import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import HomePage from './HomePage';
import ReviewerPage from './ReviewerPage';
import QuestionnairePage from './QuestionnairePage';
import SubmitSuccessfulPage from './SubmitSuccessfulPage';

// Configure Amplify with the awsExports
Amplify.configure(awsExports);

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/"               element={<HomePage />} />
        <Route path="/review"         element={<ReviewerPage />} />
        <Route path="/questionnaire"  element={<QuestionnairePage />} />
        <Route path="/submit-success" element={<SubmitSuccessfulPage />} />
      </Routes>
    </Router>
  );
};

export default App;

// npm run build
// To deploy, from command-line "As Administrator"
// aws s3 cp ./build s3://asylum-questionnaire --recursive --metadata-directive REPLACE --cache-control "no-cache"
// aws s3 cp ./my-website s3://my-bucket-name --recursive --metadata-directive REPLACE --cache-control "no-cache, no-store, must-revalidate"
