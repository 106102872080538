import React, { useState, useEffect } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { fetchUserAttributes, fetchAuthSession, signOut } from '@aws-amplify/auth';
import Header from './Header';
import './App.css';
import './ReviewerPage.css';
import backgroundImage from './DALL_E_Standing_tall_ripping_chains.webp';
import '@aws-amplify/ui-react/styles.css';

const ReviewerPage = () => {
    const [reviewerEmail, setReviewerEmail] = useState('');
    const [submissions, setSubmissions] = useState([]);
    const [submissionDetails, setSubmissionDetails] = useState([]);
    const [internalComment, setInternalComment] = useState(''); 
    const [message, setMessage] = useState(''); 
    const [currentDirectory, setCurrentDirectory] = useState('');
    const [isReviewer, setIsReviewer] = useState(false);

    useEffect(() => {
        // Fetch submissions when the component mounts
        fetchSubmissions();
    }, []);

    useEffect(() => {
        fetchUserAttributes()
            .then(user => {
                //console.log('User:', user); // Log the user object for inspection
                if (user && user.email_verified && user.email) {
                    setReviewerEmail(user.email);
                } else {
                    console.log('Email attribute missing');
                }
            })
            .catch(err => console.log('Error fetching user:', err));
    }, []);

    const fetchToken = async () => {
        const session = await fetchAuthSession();
        return session.tokens?.idToken?.toString();
    };

    const fetchData = async ({ url, method, body }) => {
        const token = await fetchToken();

        try {
            const headers = {
                'Authorization': token,
                'Content-Type': 'application/json',
            };
            const response = await fetch(url, {
                method,
                headers,
                body: JSON.stringify(body),
            });

            if (!response.ok) {
                const errorBody = await response.text();
                console.error('Network response was not ok:', errorBody);
                throw new Error('Network response was not ok');
            }

            return response.json();
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    };

    const fetchSubmissions = async () => {
        try {
            const data = await fetchData({
                url: 'https://u65adz16pe.execute-api.us-east-1.amazonaws.com/default/intake_list_submissions',
                method: 'POST',
            });
            setSubmissions(data);

            // Check if there's only one submission and automatically "click" it
            if (data.length === 1) {
                const singleSubmissionDirectory = data[0][0]; // Assuming the directory name is the first item in the submission array
                handleSubmissionClick(singleSubmissionDirectory);
                setCurrentDirectory(  singleSubmissionDirectory);
            }
        } catch (error) {
            console.error('Error fetching submissions:', error);
        }
    };

    const handleSubmissionClick = async (directoryName) => {
        try {
            const response = await fetchData({
                url: 'https://u65adz16pe.execute-api.us-east-1.amazonaws.com/default/intake_review',
                method: 'POST',
                body: { directory: directoryName },
            });
            //console.log(response)
            setIsReviewer(response.is_reviewer); // Match the property name as defined in your Lambda
            setSubmissionDetails(response.files); // Assuming the files are in a property named 'files'
        } catch (error) {
            console.error('Error fetching submission details:', error);
        }
    };
    
    const handleCommentChange = (event, type) => {
        if (type === 'internalComment') {
            setInternalComment(event.target.value);
        } else if (type === 'message') {
            setMessage(event.target.value);
        }
    };

    const submitComment = async (type) => {
        const token = await fetchToken();
        const comment = type === 'internalComment' ? internalComment : message;
        const enhancedComment = `${reviewerEmail}: ${comment}`;

        const parts = currentDirectory.split('/');
        if (parts.length < 2) {
            console.error('Invalid directory format');
            return;
        }

        const directoryPart = parts[1];
        const userIdntf = directoryPart.substring(directoryPart.length - 36);
        const userEmail = directoryPart.substring(0, directoryPart.length - 37);
    
        try {
            const commentType = type === 'internalComment' ? 'internal_comment' : 'message';

            const response = await fetch('https://u65adz16pe.execute-api.us-east-1.amazonaws.com/default/intake_questionnaire', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify({
                    userEmail,
                    userIdntf,
                    answers: { [commentType]: enhancedComment },
                }),
            });
    
            if (response.ok) {
                if (type === 'internalComment') {
                    setInternalComment('');
                } else {
                    setMessage('');
                }
                alert('Comment submitted successfully');
            } else {
                const errorBody = await response.text();
                console.error('Failed to submit comment:', errorBody);
            }
        } catch (error) {
            console.error('Error submitting comment:', error);
        }
    };

    const signMeOut = async () => {
        await signOut();
        console.log('Signing out...');
    };

    return (
        <div className="background" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <Header />
            <div className="reviewer-container">
                <div className="content">
                    <div className="submissions-list">
                        <button onClick={signMeOut} className="sign-out-button">Sign Out</button>
                        {submissions.map((submission, index) => {
                            // Extract and format the directory name
                            const fullDirectoryName = submission[0];
                            const [base, emailAndId] = fullDirectoryName.split('/');
                            const emailPart = emailAndId.slice(0, emailAndId.length - 37).replace(/_at_/, '@');
    
                            return (
                                <div key={index} className="submission-item" onClick={() => {
                                    handleSubmissionClick(fullDirectoryName);
                                    setCurrentDirectory(fullDirectoryName);
                                }}>
                                    {`${base}/${emailPart}`}
                                </div>
                            );
                        })}
                    </div>
                    {submissionDetails.length > 0 && (
                        <div className="submission-details">
                            <div className="directory-name">
                                <strong>Directory:</strong> {currentDirectory}
                            </div>
                            <div className="comment-section">
                                {isReviewer && (
                                    <>
                                        <textarea
                                            value={internalComment}
                                            onChange={(event) => handleCommentChange(event, 'internalComment')}
                                            placeholder="Add an internal comment (only for reviewers)..."
                                        />
                                        <button onClick={() => submitComment('internalComment')}>Submit Internal Comment</button>
                                    </>
                                )}
                                <textarea
                                    value={message}
                                    onChange={(event) => handleCommentChange(event, 'message')}
                                    placeholder="Add a message (general availability)..."
                                />
                                <button onClick={() => submitComment('message')}>Submit Message</button>
                            </div>
                            {submissionDetails.map((detail, index) => (
                                <div key={index} className="reviewer-container">
                                    <p className="file-info"><strong>&rarr;</strong> {detail.Key}</p>
                                    <p className="text-info" dangerouslySetInnerHTML={{ __html: detail.Text }}></p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withAuthenticator(ReviewerPage);

// Issue: Manual URL entry and reload (Cntr-R) lead to an Access Control Error. However, following the link from HomePage to the same address worked.
// Solution: CloudFront: distribution (ID: E15K1L9QPO85DT): Error Pages: 403 (and 404 separately) -> /index.html 200 