import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header'; 
import backgroundImage from './DALL_E_Sunrise_Eagle_NYC.webp';
import './App.css'; 

const HomePage = () => {
  return (
    <div 
      className="background" 
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
    <Header /> {/* Include Header */}
      <h1>Welcome to the Asylum Seeker Questionnaire (Добро пожаловать в анкету для ищущих убежища)</h1>
      <p>This is a safe place for asylum seekers to provide their information. (Это безопасное место для тех, кто ищет убежище, чтобы предоставить свою информацию.)</p>
      <p>Here, we only collect your e-mail address so that we are able to contact you directly. You do not need to provide your name or any other identifying information. (Здесь мы собираем только ваш адрес электронной почты, чтобы мы могли связаться с вами напрямую. Вам не нужно предоставлять ваше имя или любую другую идентифицирующую информацию.)</p>
      <p><Link to="/questionnaire">Go to Questionnaire (Перейти к анкете)</Link></p>
      <p><Link to="/review">Go to Review (Перейти к обзору)</Link></p>
      <p><a href="https://www.vstnk.org">Go back to VESTNIK HomePage (Вернутся на главную страницу ВЕСТНИК) [vstnk.org]</a></p>
    </div>
  );
};

export default HomePage;
