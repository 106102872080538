import React, { useState, useEffect } from 'react';
import Header from './Header'; 
import { withAuthenticator } from '@aws-amplify/ui-react';
import { fetchUserAttributes, fetchAuthSession, signOut } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import './App.css';
import backgroundImage from './DALL_E_Walls_Breaking_Chains.webp';
import '@aws-amplify/ui-react/styles.css';

// Assume questionsConfig is your imported configuration JSON file
// Format: [{questionName: string, question: string, dependent: string, size: int, required: bool}, ...]
import questionsConfig from './intake1.json';

const QuestionnairePage = () => {
    const navigate = useNavigate();
    const [userEmail, setUserEmail] = useState('');
    const [userIdntf, setUserIdntf] = useState('');
    const [answers, setAnswers] = useState({});
    const [displayQuestions, setDisplayQuestions] = useState([]);
    const [yesNoAnswers, setYesNoAnswers] = useState({}); // New state to track Yes/No answers

    useEffect(() => {
        // Handle Sign Out
        const handleSignOutBeforeUnload = async () => {
            await signOut();
        }
        window.addEventListener('beforeunload', handleSignOutBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleSignOutBeforeUnload);
        };
    }, []);

    useEffect(() => {
        // Fetch User Attributes
        fetchUserAttributes()
            .then(user => {
                if (user && user.email_verified && user.email) {
                    setUserEmail(user.email);
                    setUserIdntf(user.sub);
                } else {
                    console.log('Email attribute missing');
                }
            })
            .catch(err => console.log('Error fetching user:', err));

        // Initialize questions display based on dependencies
        setDisplayQuestions(questionsConfig.filter(q => !q.dependent));
    }, []);

    useEffect(() => {
        // Initialize or update display questions based on both independent questions and the current state of yesNoAnswers
        const initialQuestions = questionsConfig.filter(q => !q.dependent);
        const dependentQuestionsToShow = questionsConfig.filter(q => 
            q.dependent && yesNoAnswers[q.dependent] === 'Yes'
        );
        const allQuestionsToShow = [...initialQuestions, ...dependentQuestionsToShow];
        
        // Sort by original order in questionsConfig to maintain the intended sequence
        const sortedQuestionsToShow = allQuestionsToShow.sort((a, b) => {
            return questionsConfig.findIndex(config => config.questionName === a.questionName) -
                   questionsConfig.findIndex(config => config.questionName === b.questionName);
        });
    
        setDisplayQuestions(sortedQuestionsToShow);
    }, [yesNoAnswers]); // Dependency array now includes yesNoAnswers to re-evaluate displayed questions when it changes

    const updateAnswer = (questionName, value, isYesNoQuestion = false) => {
        setAnswers(prevAnswers => ({ ...prevAnswers, [questionName]: value }));
    
        if (isYesNoQuestion) {
            setYesNoAnswers(prevYesNoAnswers => {
                const newYesNoAnswers = { ...prevYesNoAnswers, [questionName]: value };
    
                if (value.toLowerCase() === 'no') {
                    // Recursively define a function to remove dependent questions
                    const recursivelyRemoveDependents = (qName, currentYesNoAnswers) => {
                        const dependentQuestions = questionsConfig.filter(q => q.dependent === qName);
                        dependentQuestions.forEach(dq => {
                            delete currentYesNoAnswers[dq.questionName]; // Remove the answer for the dependent question
                            recursivelyRemoveDependents(dq.questionName, currentYesNoAnswers); // Recursively remove answers for any sub-dependents
                        });
                    };
    
                    // Execute the recursive function starting with the current question
                    recursivelyRemoveDependents(questionName, newYesNoAnswers);
                }
    
                return newYesNoAnswers;
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const token = (await fetchAuthSession()).tokens?.idToken?.toString();
            // Concatenate questions with answers
            const formattedAnswers = Object.entries(answers).reduce((acc, [key, value]) => {
                const questionText = questionsConfig.find(q => q.questionName === key).question;
                // Concatenate question text and answer with a line break
                acc[key] = `Question: ${questionText}<br />Answer: ${value}`;
                return acc;
            }, {});
            
            const response = await fetch('https://u65adz16pe.execute-api.us-east-1.amazonaws.com/default/intake_questionnaire', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({ userEmail, userIdntf, answers: formattedAnswers }),
            });

            if (response.ok) {
                const responseData = await response.json();
                console.log(responseData);
        
                await signOut();
                navigate('/submit-success');
            } else {
                const errorBody = await response.text();
                console.error('Failed to submit questionnaire:', errorBody);
            }
        } catch (error) {
            console.error('Error submitting questionnaire:', error);
        }
    };

    return (
      <form onSubmit={handleSubmit}>
        <div 
            className="background" 
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
  
        <div>
          <Header />
        </div>
        {displayQuestions.map(question => (
            <div key={question.questionName} className="question-label"> {/* Apply padding to each question */}
                <div>
                    <label>{question.question}:</label>
                </div>
                {question.size > 0 ? (
                    <textarea
                        className="resizable-textbox" // Padding defined in CSS
                        style={{
                            width: '95%', // Keeps your existing inline style
                            height: `${20 * (question.size / 100)}px` // And this one
                        }}
                        value={answers[question.questionName] || ''}
                        onChange={(e) => updateAnswer(question.questionName, e.target.value)}
                    />
                ) : (
                    <div className="radio-container"> {/* Container for radio buttons with padding */}
                        <input
                            type="radio"
                            name={question.questionName}
                            className="radio-input" // Apply class for individual radio input
                            checked={answers[question.questionName] === 'Yes'}
                            onChange={() => updateAnswer(question.questionName, 'Yes', true)}
                        />
                        <label>Yes</label>
                        <input
                            type="radio"
                            name={question.questionName}
                            className="radio-input" // Apply class for individual radio input
                            checked={answers[question.questionName] === 'No'}
                            onChange={() => updateAnswer(question.questionName, 'No', true)}
                        />
                        <label>No</label>
                    </div>
                )}
            </div>
        ))}
        </div>
        <button type="submit">Submit</button>
      </form>
  );
};

export default withAuthenticator(QuestionnairePage);
