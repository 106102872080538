import React from 'react';
import Header from './Header'; 
import { Link } from 'react-router-dom';

const SubmitSuccessfulPage = () => {
    return (
        <div>
            <Header /> {/* Include Header */}
            <h1>Submission Successful!</h1>
            <p>Your form has been successfully submitted.</p>
            <Link to="/">Go to Home Page</Link>
        </div>
    );
};

export default SubmitSuccessfulPage;
